export const httpCall = async (method, url, idToken, body) => {
    var header = { 'Content-Type': 'application/json' };

    console.log(url)

    if (idToken != null) {
        header['Authorization'] = 'Bearer ' + idToken;
    }

    var requestOptions = {
        method: method,
        headers: header,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);
        const json = await response.json();

        return ([true, null, response.status, json]);
    } catch (error) {
        console.log("error", error);
        return ([false, error, null, null]);
    }
}